<template>
	<div class="contentBox">
		<div class="hoome-goods-list">
			<div v-for="(item, i) in list" :key="i" data-flex="dir:top cross:center" class="item" @click="toDetail(item.id)">
				<div>
					<div v-if="isEmpty(item.mainImage)" class="no-image flex-ttb flex-cc flex-mc" >
						<text class="iconfont icon-camera" ></text>
						<text >暂无图片</text>
					</div>
					<img v-else :src="item.mainImage | qiniuResource" class="img1"/>
					<div class="txt1">
						{{ item.title }}
					</div>
					<div class="txt2">
                        <div data-flex="cross:center">
                            <div class="f-fs14 f-fwb" data-flex-box="1">
                                <span class="f-fs12">￥</span>
                                <span class="f-fs16">{{item.price}}</span>
                            </div>
                            <div v-if="type == 2" class="f-fc4 f-fs12" data-flex-box="0">相似度{{item.score}}</div>
                        </div>
                        <!-- <div data-flex="cross:center">
                            <div class="f-fs14 f-fc10" data-flex-box="1">
                                <span class="f-fs12">￥</span>
                                <span class="f-fs12 f-tlt">{{item.costprice}}</span>
                            </div>
                            <div v-if="type == 2" class="f-fc4 f-fs12" data-flex-box="0">相似度{{item.score}}</div>
                        </div> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['list', 'type'],
	data() {
		return {}
	},
	watch: {

	},
	methods: {
		toDetail(id) {
            this.$router.push('/goods/detail?id=' + id)
		}
	}
}
</script>

<style lang="less" scoped>


.hoome-goods-list {
	margin-top: 20px;
	padding: 0 15px;
	box-sizing: border-box;

    .item {
        background: #fff;
        display: inline-block;
        margin: 0 10px 20px;
        border-radius: 15px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);

        > div:first-child {
            width: 340px;
            overflow: hidden;
            position: relative;
        }

        .no-image {
            width: 100%;
            height: 100%;
            background: #f8f8f8;
            position: absolute;
            z-index: 1;

            > text {
                color: #999;

                &:first-child {
                    font-size: 48px;
                }

                &:last-child {
                    font-size: 24px;
                }
            }
        }

        .img1 {
            border-radius: 15px 15px 0 0;
            width: 340px;
            height: 340px;
        }

        .txt1 {
            padding: 0 20px;
            box-sizing: border-box;
            width: 100%;
            min-height: 70px;
            line-height: 35px;
            font-size: 14px;
            color: #333;
            margin: 20px 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }

        .txt2 {
            padding: 0 20px 20px;
            box-sizing: border-box;
            margin-top: 30px;
            > div:nth-child(1){
                margin-bottom: 5px;
            }
        }
    }
}

</style>
