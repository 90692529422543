<template>
    <div class="page">
        <Search ref="search" :kw="serchData.kw" @onSearch="onSearch" />
        <!-- 产品 -->
        <div v-if="isDataLoaded">
            <div class="tabbody">
                <div class="hoome-goods" v-if="!isEmpty(list)">
                    <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoad"
                    >
                        <GoodsList :list="list"></GoodsList>
                    </van-list>
                </div>
                <van-empty v-else description="暂无结果，建议您换一个关键词试试" />
            </div>
        </div>

    </div>
</template>

<script>


import mix1 from '@/mixins/page'
import minList from '@/mixins/list'

import { uiShowLoading, uiHideLoading, uiAlert, uiConfirm } from '@/utils/ui'
import { localStorageGet, localStorageSet, localStorageRemove } from '@/utils/storage'
import { delayAsync } from '@/utils/common'
import { Button, NumberKeyboard, Field, Empty, List } from 'vant'
import MgPopup from '@/components/common/mg-popup'
import TabsBox from '@/components/common/tabs'

import Search from "./components/search.vue";
import GoodsList from './components/list.vue'


export default {
    mixins: [mix1, minList],
    components: {
        Search,
        GoodsList,
        MgPopup, TabsBox, Button, NumberKeyboard,
        [Field.name]: Field,
        [Button.name]: Button,
        [Empty.name]: Empty,
        [List.name]: List,
        
    },
    data() {
        return {
            isDataLoaded: false,
            serchData: {
                kw: "",
            },
            list: [],
        }
    },
    methods: {
        onSearch(e){
            this.serchData.kw = e || ''
            this.getList(true)
        },
        onLoad() {
            // 下拉
            // 加载状态开始
            this.loading = true;
            this.pagination.page += 1
            
            this.getList()
        },
        async getList(type) {
            if(type){
                this.loading = true;
                this.finished = false;
                this.pagination.page = 1
                this.list = []
            }
            uiShowLoading()
            await delayAsync(500)

            const promise = this.apiGet('goods.list', {
                ...this.serchData,
                ...this.pagination
            })

            let apiResult = null

            try {
                apiResult = await promise
            } catch (ex) {
                apiResult = null
            }

            uiHideLoading()

            if (this.hasException(apiResult)) {
                return
            }
            this.isDataLoaded = true
            
            // 加载状态结束
            this.loading = false;

            this.list = [...this.list, ...apiResult.list]
            this.$refs['search'].kw = this.serchData.kw

            // 数据全部加载完成
            if (this.list.length == apiResult.pagination.recordTotal) {
                this.finished = true;
            }
        },

    },
    mounted() {

    },
    created() {
        let query = this.$route.query
        console.log('query', query)
        this.serchData.kw = query.kw || ''
        this.getList(true)
    }
}
</script>

<style lang="less" scoped>

.van-empty__image {
    width: 500px;
    height: 500px;
}
.page {
    padding-top: 100px;
}
</style>