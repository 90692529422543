<template>
    <div class="content">
        <div class="haeders_box">
            <div class="haeders" data-flex="cross:center main:center">
                <div data-flex-box="1"  >
                    <van-search background="#f7f8fa" v-model="kw" placeholder="请输入搜索关键词" @search="onSearch"/>
                </div>
                <img class="icon2" data-flex-box="0" src="../../../assets/images/xj.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>

import { Field, Search } from 'vant'
export default {
    props: [],
    components: {
        [Field.name]: Field,
        [Search.name]: Search,
    },
    data() {
        return {
            kw: ''
        }
    },
    watch: {},
    methods: {
        onSearch(){
            this.$emit('onSearch', this.kw)
        }
    }
}
</script>

<style lang="less" scoped>

.haeders_box {
    width: 100%;
    background: #fff;
    position: relative;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    height: 88px;
    padding: 10px 20px;
    box-sizing: border-box;
    
    .haeders {
        height: 68px;
        box-sizing: border-box;
        width: 100%;
        background: #f7f8fa;
        border-radius: 100px;
        padding:0 20px;
        box-sizing: border-box;
        // input{
        //     background: #f7f8fa;
        //     height: 64px;
        //     padding: 0 10px;
        //     border: none;
        //     font-size: 14px;
        // }
        img {
            width: 40px;
            height: 40px;
        }
        .icon1 {
            width: 25px;
            height: 25px;
        }
        .icon2 {
            width: 35px;
            height: 30px;
        }
        .van-search{
            padding: 0px;
            .van-search__content{
                padding-left: 0px;
            }
            .van-field__body{
                padding-right: 10px;
            }
        }
    }
}
</style>
